import { useMainMachine } from '../../../utils/useMainMachine';
import { Modal } from '../../../modal/Modal';
import { Button } from '../basic-ui/Button';
import { ACTIONS } from '../../../state';

import WarningIcon from '@crazyegginc/hatch/dist/images/icon-warning-filled.svg?react';
import DownloadIcon from '@crazyegginc/hatch/dist/images/icon-download.svg?react';

export function ErrorLoadingPageModal({ ...props }) {
  const { useSelector, machine } = useMainMachine();
  const urlToLoad = useSelector(({ context }) => context.urlToLoad);

  return (
    <Modal dialogClassName="!top-[150px] !translate-y-0" dismissable={false} disableOutsideClick={true} {...props}>
      <WarningIcon className="w-9 h-9 fill-current text-[#FFB202]" />
      <div className="mt-2 text-2xl font-semibold">Error loading page</div>
      {window.isElectron ? (
        <div className="text-gray-200">
          The page <span className="text-white">{urlToLoad}</span> is not loading. Please check the URL and try again.
        </div>
      ) : (
        <div className="text-gray-200">
          The page <span className="text-white">{urlToLoad}</span> is having trouble loading in your browser. Please
          download the Crazy Egg app to access and edit this page.
        </div>
      )}
      <Modal.Actions>
        <Button
          variant="cancel"
          size="lg"
          onClick={() => {
            machine.send({ type: ACTIONS.ERROR_MODAL_BACK });
          }}
        >
          Go back
        </Button>
        {window.isElectron ? null : (
          <Button
            size="lg"
            onClick={() => {
              machine.send({ type: ACTIONS.DOWNLOAD_APP });
            }}
          >
            <DownloadIcon className="w-3.5 h-3.5 fill-current mr-2" />
            Download app
          </Button>
        )}
      </Modal.Actions>
    </Modal>
  );
}
