import { useState } from 'react';
import classNames from 'classnames';

import { useMainMachine } from '../../../utils/useMainMachine';
import { Button } from '../basic-ui/Button';
import { CloseButton } from '../basic-ui/CloseButton';
import { BUTTON_TYPES } from '../../constants';
import { ACTIONS } from '../../../state/actions';

import DownloadIcon from '@crazyegginc/hatch/dist/images/icon-download.svg?react';

export function DownloadAppButton() {
  const { machine } = useMainMachine();
  const [open, setOpen] = useState(false);

  return (
    <div className="relative">
      {open ? (
        <div
          className={classNames(
            'absolute p-2.5 bottom-full -translate-y-[25px] -left-7 w-[260px]',
            'rounded shadow-md bg-woodsmoke-500  drop-shadow-border',
          )}
        >
          <div className="text-lg font-bold text-white leading-tight">Want a faster, more optimized performance?</div>
          <div className="mb-5 mt-1 text-gray-200">Download our editor desktop app!</div>
          <Button
            onClick={() => {
              machine.send({ type: ACTIONS.DOWNLOAD_APP });
            }}
          >
            <DownloadIcon className="w-3.5 h-3.5 fill-current mr-2" />
            Download app
          </Button>
          <div
            className={classNames(
              'absolute h-0 w-0 border-5 border-transparent',
              'top-full -translate-x-1/2 left-[47px] border-t-woodsmoke-500',
            )}
          />
          <CloseButton onClick={() => setOpen(false)} buttonClass="!right-3 !top-3" />
        </div>
      ) : null}
      <Button
        variant={BUTTON_TYPES.SECONDARY}
        onClick={() => {
          setOpen((x) => !x);
        }}
      >
        <DownloadIcon className="w-3.5 h-3.5 fill-current" />
      </Button>
    </div>
  );
}
