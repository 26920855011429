import { useLayoutEffect } from 'react';
import classNames from 'classnames';

import { useMainMachine } from '../../../utils/useMainMachine';
import { ACTIONS } from '../../../state/actions';

export function NarrowSelectedElement({ setShowContentType }) {
  const { useSelector, machine } = useMainMachine();
  const selectedElement = useSelector(({ context }) => context.selectedElement);
  const childNodes = selectedElement.editableChildNodes;

  useLayoutEffect(() => {
    if (childNodes?.length === 1) {
      machine.send({ type: ACTIONS.SELECT_ELEMENT, selector: childNodes[0].selector });
    }
  }, [childNodes, machine]);

  return (
    <div data-testid="selection-helper" className="flex flex-col">
      <div>Select the element you want to edit:</div>
      {selectedElement.hasBgImage && (
        <SelectButton
          onClick={() => {
            setShowContentType('image');
          }}
        >
          {selectedElement.bgImageDisaplyContent[0]}
        </SelectButton>
      )}
      {selectedElement.hasImmediateChildTextNode && (
        <SelectButton
          onClick={() => {
            setShowContentType('text');
          }}
        >
          {selectedElement.text}
        </SelectButton>
      )}
      {childNodes?.length > 0
        ? childNodes.map((n) => (
            <SelectButton
              key={n.selector}
              onClick={() => machine.send({ type: ACTIONS.SELECT_ELEMENT, selector: n.selector })}
            >
              {n.textContent.join(' > ')}
            </SelectButton>
          ))
        : null}
    </div>
  );
}

function SelectButton({ onClick, children }) {
  return (
    <button
      type="button"
      className={classNames(
        'relative text-sm text-left mt-2.5 p-2.5 rounded-md border ',
        'bg-gray-200/10 text-gray-200 hover:text-white border-gray-200/50 hover:border-gray-200',
      )}
      onClick={onClick}
    >
      <div className="line-clamp-2">{children}</div>
    </button>
  );
}
