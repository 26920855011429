import { DEVICES, MOBILE_OPTIONS, VOICES } from '../editor-ui/constants';

export const initialContext = {
  device: DEVICES.DESKTOP,
  mobile: MOBILE_OPTIONS.filter((x) => !x.disabled)[0].value,
  editor: {
    pages: [],
  },
  voice: VOICES.LUCKY,
  canUndo: false,
  canRedo: false,
  isDirty: false,
  wordsChanged: 0,
  imagesChanged: 0,
  userId: null,
  saved: false,
  saving: false,
  generatorVoiceUsed: null,
  doneManualEdits: false,
  edited: {
    content_area: false,
    ai_rephrase: false,
    ai_image: false,
    manual_image: false,
    image_dimensions: false,
    content_inline: false,
    text_style: false,
    font_size: false,
    font: false,
    color: false,
    alignment: false,
    add_link: false,
    advanced: false,
  },
  pagesOpen: true,
  versionsOpen: false,
  controlsOpen: true,
  urlToLoad: null,
};
