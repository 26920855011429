export const STATES = {
  INIT: 'init',
  MOBILE_ACCESS: 'mobileAccess',
  LOAD_SHARING_URL: 'loadSharingUrl',
  API_INIT: 'api_init',
  REQUEST_FIRST_PAGE: 'requestFirstPage',
  ADDING_FIRST_PAGE: 'addingFirstPage',
  FIRST_PAGE_LOAD_FAILED: 'firstPageLoadFailed',
  INITIALIZED: 'initialized',
  GENERAL: 'general',
  GENERATOR: 'generator',
  EDITOR: 'editor',
  IDLE: 'idle',
  ADD_PAGE_MODAL: 'addPageModal',
  ADDING_PAGE: 'addingPage',
  PAGE_LOAD_FAILED: 'pageLoadFailed',
  INIT_SHARING_SESSION: 'initSharingSession',
  SAVING: 'save',
  SELECTING_VERSION: 'selectingVersion',
  PROMPT_UNSAVED_CHANGES: 'promptUnsavedChanges',
  GENERATING_FULL_PAGE: 'generatingFullPage',
  AI_SUGGESTIONS: 'aiSuggestions',
  PUBLISHING: 'publishing',
  HIDDEN: 'hidden',
  EDIT: 'edit',
};
