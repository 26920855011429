import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

import { useMainMachine } from '../../../utils/useMainMachine';
import { DisclosureButton } from '../basic-ui/DisclosureButton';
import { ACTIONS, STATES } from '../../../state';
import { AISuggestions } from './AISuggestions';
import { ElementEditor } from './ElementEditor';
import { getVoice } from '../../../utils/utils';

export function Controls() {
  const { useSelector, machine } = useMainMachine();
  const selectedElement = useSelector(({ context }) => context.selectedElement);

  const showAISuggestions = useSelector((state) =>
    state.matches({ [STATES.INITIALIZED]: { [STATES.EDITOR]: STATES.AI_SUGGESTIONS } }),
  );
  const voice = useSelector(({ context }) => context.voice);
  const [usedVoice, setUsedVoice] = useState(getVoice(voice));
  const open = useSelector(({ context }) => context.controlsOpen);
  const [showContentType, setShowContentType] = useState();

  useEffect(() => {
    setShowContentType(null);
  }, [selectedElement?.selector]);

  if (!selectedElement) return null;

  const needsContentTypeSelection =
    !showContentType &&
    (selectedElement.hasImmediateChildTextNode || selectedElement.editableChildNodes.length > 0) &&
    selectedElement.hasBgImage;

  const showImage =
    (selectedElement.isEditable && selectedElement.isImage) ||
    (selectedElement.hasBgImage && !needsContentTypeSelection && showContentType !== 'text');
  const showContent = (selectedElement.isEditable || showImage) && !needsContentTypeSelection;

  return (
    <div className="flex flex-col" data-testid="text-controls">
      <div className="h-px bg-gray-600 my-5 -mx-5" />

      <div className="flex items-center justify-between">
        <div className="w-full">
          {showAISuggestions ? (
            <button type="button" onClick={() => machine.send({ type: ACTIONS.BACK })}>
              Back
            </button>
          ) : (
            <span className="font-semibold">{showContent ? (showImage ? 'Image' : 'Text') : 'Element'}</span>
          )}
        </div>
        {showAISuggestions ? (
          <div className="w-full flex justify-center font-semibold whitespace-nowrap">AI suggestions</div>
        ) : null}
        <div className="w-full flex justify-end">
          <DisclosureButton
            open={open}
            onClick={() => machine.send({ type: ACTIONS.TOGGLE_CONTROLS })}
            contentId="controls-content"
          />
        </div>
      </div>
      <div className="">
        <motion.div
          initial={false}
          animate={
            open
              ? {
                  height: 'auto',
                  opacity: 1,
                }
              : {
                  height: 0,
                  opacity: 0,
                }
          }
          transition={{ duration: 0.4, ease: 'linear' }}
          className="mt-2.5 flex flex-col"
          id="controls-content"
          data-testid="controls-content"
        >
          {showAISuggestions ? (
            <AISuggestions usedVoice={usedVoice} setUsedVoice={setUsedVoice} />
          ) : (
            <ElementEditor
              setUsedVoice={setUsedVoice}
              key={selectedElement.selector}
              showContent={showContent}
              showImage={showImage}
              setShowContentType={setShowContentType}
            />
          )}
        </motion.div>
      </div>
    </div>
  );
}
