import { setup, assign, forwardTo, raise, fromPromise, fromCallback, not, and, enqueueActions, sendTo } from 'xstate';
import { createActorContext } from '@xstate/react';
import Honeybadger from '@honeybadger-io/js';
//import { createBrowserInspector } from '@statelyai/inspect';
import crazyAi from '@crazyegginc/ai';
import mockAI from '../ai/mockAI';
import { PageEditorAPI } from '@crazyegginc/page-editor-api';

import { AddFirstPageModal } from '../editor-ui/components/modals/AddFirstPage';
import { AddPageModal } from '../editor-ui/components/modals/AddPage';
import { SaveChangesModal } from '../editor-ui/components/modals/SaveChanges';
import { HistoryModal } from '../editor-ui/components/modals/History';
import { MobileAccessModal } from '../editor-ui/components/modals/MobileAccess';
import { ReloadPageModal } from '../editor-ui/components/modals/ReloadPage';
import { ErrorLoadingPageModal } from '../editor-ui/components/modals/ErrorLoadingPage';
import { SlowLoadingPageModal } from '../editor-ui/components/modals/SlowLoadingPage';

import { initialContext } from './initialContext';
import { modalMachine, MODAL_ACTIONS } from '../modal/modalMachine';
import { notificationLogic, NOTIFICATION_ACTIONS } from '../notification/notificationLogic';
import {
  apiAddPage,
  apiEventListeners,
  apiInit,
  aiInit,
  generateFullPage,
  sessionInit,
  sharingSessionInit,
  apiSave,
  apiSelectVersion,
  beforeUnloadListener,
  apiPublish,
  STATES,
  ACTIONS,
} from '.';
import { DEVICES, NOTIFICATION_TYPES, DEVICE_TO_INT, LOADING, METRICS_PREFIX } from '../editor-ui/constants';
import { sendMetric } from '../utils/metrics';
import { hasSearchParam, getSearchParam, setSearchParam } from '../utils/location';

export const MODAL_MACHINE_ID = 'modalMachineId';
export const NOTIFICATION_LOGIC_ID = 'notificationLogicId';
export const BEFORE_UNLOAD_LISTENER_ID = 'beforeUnloadListenerId';
//const { inspect } = createBrowserInspector();

let singleApi;
let singleAI;

const mainMachine = setup({
  delays: {
    pageLoadTimeout: 30000,
  },
  actions: {
    saveStatus: assign({
      editor: ({ event }) => event.detail.editor,
      canUndo: ({ event }) => event.detail.canUndo,
      canRedo: ({ event }) => event.detail.canRedo,
      isDirty: ({ event }) => event.detail.isDirty,
      wordsChanged: ({ event }) => event.detail.wordsChanged,
      imagesChanged: ({ event }) => event.detail.imagesChanged,
      metadata: ({ event }) => event.detail.metadata,
    }),
    forwardDirtyChanged: sendTo(BEFORE_UNLOAD_LISTENER_ID, ({ event }) => ({
      type: ACTIONS.DIRTY_CHANGED,
      dirty: event.detail.isDirty,
    })),
    saveInitData: assign({
      api: ({ event }) => event.output.api,
      anonymousId: ({ event }) => event.output.anonymousId,
      userId: ({ event }) => event.output.userId,
    }),
    saveAI: assign({
      ai: ({ event }) => event.output.ai,
    }),
    savePageContext: assign({
      pageContext: ({ event }) => event.output.pageContext,
    }),
    idToSearchParam: ({ event }) => {
      setSearchParam('editorid', event.output.editor.id);
    },
    changeDevice: enqueueActions(({ enqueue, context, event }) => {
      enqueue(({ event, context }) => {
        if (event.device === context.device && event.mobile === context.mobile) {
          return;
        }
        event.device === DEVICES.MOBILE
          ? context.api.changeDevice(event.device, event.width, event.dpr)
          : context.api.changeDevice(event.device);
      });
      if (event.device !== context.device) {
        enqueue({
          type: 'internalMetric',
          params: { name: 'device_view_switched', params: { device: DEVICE_TO_INT[event.device] } },
        });
      } else if (event.device == context.device && event.device == DEVICES.MOBILE && context.mobile !== event.mobile) {
        enqueue({
          type: 'internalMetric',
          params: { name: 'mobile_resolution_switched', params: { resolution: event.text } },
        });
      }
      enqueue.assign({
        device: event.device,
        mobile: event.mobile ?? context.mobile,
      });
    }),
    apiHidePopups: ({ context }) => {
      context.api.hidePopups();
    },
    apiSetText: ({ event, context }) => {
      context.api.setText(event.text);
    },
    apiUndo: ({ context }) => {
      context.api.undo();
    },
    apiRedo: ({ context }) => {
      context.api.redo();
    },
    apiSelectElement: ({ context, event }) => {
      context.api.selectElement(event.selector);
    },
    changeVoice: assign({
      voice: ({ event }) => event.voice,
    }),
    elementSelected: enqueueActions(({ enqueue, context, event }) => {
      if (Object.values(context.edited).some(Boolean)) {
        enqueue({
          type: 'internalMetric',
          params: { name: 'element_edited', params: { ...context.edited } },
        });
      }
      enqueue.assign({
        selectedElement: event.el,
        edited: { ...initialContext.edited },
      });
      enqueue({
        type: 'internalMetric',
        params: {
          name: 'element_selected',
          params: { type: event.el.isEditable ? (event.el.imgProps?.url ? 'image' : 'text') : 'group' },
        },
      });
    }),
    elementUnSelected: enqueueActions(({ enqueue, context }) => {
      if (Object.values(context.edited).some(Boolean)) {
        enqueue({
          type: 'internalMetric',
          params: { name: 'element_edited', params: { ...context.edited } },
        });
      }
      enqueue.assign({
        selectedElement: null,
        edited: { ...initialContext.edited },
      });
    }),
    selectedElementUpdated: assign({
      selectedElement: ({ event }) => event.el,
    }),
    saveEditorState: assign({
      editor: ({ event }) => event.output.editor,
    }),
    setActivePage: assign({
      activePageId: ({ event }, params) => params?.pageId ?? event.output.pageId,
    }),
    setActiveVersion: assign({
      activeVersionId: (_, params) => params.versionId,
    }),
    setClean: assign({
      isDirty: false,
    }),
    setSaved: assign({
      saved: true,
    }),
    clearSaved: assign({
      saved: false,
    }),
    setSaving: assign({
      saving: true,
    }),
    clearSaving: assign({
      saving: false,
    }),
    raiseError: enqueueActions(({ enqueue }, params) => {
      enqueue.raise(() => ({
        type: NOTIFICATION_ACTIONS.ADD,
        notification: {
          type: NOTIFICATION_TYPES.ERROR,
          title: params.title,
          text: typeof params.error === 'object' ? params.error.message : params.error,
          extraText: typeof params.error === 'object' ? params.error.stack : null,
        },
      }));
      enqueue(() => {
        Honeybadger.notify(params.error, { context: params.context });
      });
    }),
    honeyBadgerNotify: (_, params) => {
      Honeybadger.notify(params.error, { context: params.context });
    },
    closeModal: raise({ type: MODAL_ACTIONS.CLOSE }),
    saveEvent: assign({
      savedEvent: ({ event }) => event,
    }),
    clearSavedEvent: assign({
      savedEvent: null,
    }),
    raiseSavedEvent: enqueueActions(({ enqueue, check, context }) => {
      if (check('hasSavedEvent')) {
        const savedEvent = { ...context.savedEvent };
        enqueue('closeModal');
        enqueue('clearSavedEvent');
        enqueue.raise(savedEvent);
      }
    }),
    internalMetric: ({ context }, { name, params }) => {
      sendMetric(`${METRICS_PREFIX}_${name}`, {
        anonymous_id: context.anonymousId,
        user_id: context.userId,
        ...params,
      });
    },
    showHistory: enqueueActions(({ enqueue }) => {
      enqueue.raise({ type: MODAL_ACTIONS.SHOW, component: <HistoryModal /> });
      enqueue({
        type: 'internalMetric',
        params: { name: 'history_viewed' },
      });
    }),
    setGeneratorVoiceUsed: assign({
      generatorVoiceUsed: ({ event }) => event.output.voice,
    }),
    setSelectedElText: enqueueActions(({ enqueue }) => {
      enqueue.assign({
        doneManualEdits: true,
      });
      enqueue(({ event, context }) => {
        context.api.setText(event.text, null, event.voice ? { voice: event.voice } : null);
      });
    }),
    setSelectedElHTML: enqueueActions(({ enqueue }) => {
      enqueue.assign({
        doneManualEdits: true,
      });
      enqueue(({ event, context }) => {
        context.api.setHTML(event.html, null, event.voice ? { voice: event.voice } : null);
      });
    }),
    setSelectedElAttribute: enqueueActions(({ enqueue }) => {
      enqueue.assign({
        doneManualEdits: true,
      });
      enqueue(({ event, context }) => {
        context.api.editAttribute(event.attribute, event.value, null, event.voice ? { voice: event.voice } : null);
      });
    }),
    setSelectedElImage: enqueueActions(({ enqueue }) => {
      enqueue.assign({
        doneManualEdits: true,
      });
      enqueue(({ event, context }) => {
        context.api.setImage(event.url, null, event.voice ? { voice: event.voice } : null);
      });
    }),
    clearDoneManualEdits: assign({
      doneManualEdits: false,
    }),
    clearGeneratorVoiceUsed: assign({
      generatorVoiceUsed: null,
    }),
    editedAiRephrase: assign({
      edited: ({ context }) => ({ ...context.edited, ai_rephrase: true }),
    }),
    editedAiImage: assign({
      edited: ({ context }) => ({ ...context.edited, ai_image: true }),
    }),
    editedManualImage: assign({
      edited: ({ context }) => ({ ...context.edited, manual_image: true }),
    }),
    editedContentArea: assign({
      edited: ({ context }) => ({ ...context.edited, content_area: true }),
    }),
    togglePages: assign({
      pagesOpen: ({ context }) => !context.pagesOpen,
    }),
    toggleVersions: assign({
      versionsOpen: ({ context }) => !context.versionsOpen,
    }),
    toggleControls: assign({
      controlsOpen: ({ context }) => !context.controlsOpen,
    }),
    setVersionsOpen: assign({
      versionsOpen: (_, params) => params?.open,
    }),
    setControlsOpen: assign({
      controlsOpen: (_, params) => params?.open,
    }),
    setUrlToLoad: assign({
      urlToLoad: ({ event }) => event.url,
    }),
    clearUrlToLoad: assign({
      urlToLoad: null,
    }),
    recordPageLoadStartTime: assign({
      pageLoadStartTime: performance.now(),
    }),
    pageLoadedMetric: enqueueActions(({ enqueue, context }) => {
      enqueue({
        type: 'internalMetric',
        params: {
          name: 'page_loaded',
          params: { elapsed_time: Math.round(performance.now() - context.pageLoadStartTime) },
        },
      });
    }),
    downloadApp: ({ context }) => {
      context.api.downloadApp();
    },
    apiCancel: ({ context }) => {
      context.api.cancel();
    },
  },
  actors: {
    modalMachine: modalMachine,
    notificationLogic: notificationLogic,
    apiInit: fromPromise(apiInit),
    sharingSessionInit: fromPromise(sharingSessionInit),
    sessionInit: fromPromise(sessionInit),
    aiInit: fromPromise(aiInit),
    apiEventListeners: fromCallback(apiEventListeners),
    apiAddPage: fromPromise(apiAddPage),
    apiSave: fromPromise(apiSave),
    apiSelectVersion: fromPromise(apiSelectVersion),
    beforeUnloadListener: fromCallback(beforeUnloadListener),
    generateFullPage: fromPromise(generateFullPage),
    apiPublish: fromPromise(apiPublish),
  },
  guards: {
    isMobileDevice: () => window.matchMedia('only screen and (max-width: 760px)').matches,
    hasEditorIdSearchParam: () => hasSearchParam('editorid'),
    isSamePage: ({ context, event }) => context.activePageId === event.pageId,
    isSameVersion: ({ context, event }) => context.activeVersionId === event.versionId,
    isDirty: ({ context }) => context.isDirty === true && context.canUndo === true,
    hasSavedEvent: ({ context }) => Boolean(context.savedEvent),
  },
}).createMachine({
  context: { ...initialContext },
  initial: STATES.INIT,
  invoke: [
    {
      src: 'modalMachine',
      id: MODAL_MACHINE_ID,
    },
    {
      src: 'notificationLogic',
      id: NOTIFICATION_LOGIC_ID,
    },
  ],
  on: {
    [MODAL_ACTIONS.SHOW]: { actions: forwardTo(MODAL_MACHINE_ID) },
    [MODAL_ACTIONS.CLOSE]: { actions: forwardTo(MODAL_MACHINE_ID) },
    [NOTIFICATION_ACTIONS.ADD]: { actions: forwardTo(NOTIFICATION_LOGIC_ID) },
    [NOTIFICATION_ACTIONS.REMOVE]: { actions: forwardTo(NOTIFICATION_LOGIC_ID) },
    [NOTIFICATION_ACTIONS.REMOVE_ALL]: { actions: forwardTo(NOTIFICATION_LOGIC_ID) },
    [ACTIONS.ERROR_DETECTED]: {
      actions: [
        {
          type: 'honeyBadgerNotify',
          params: ({ event }) => ({
            error: event.error,
            context: { apiError: true },
          }),
        },
        raise({
          type: MODAL_ACTIONS.SHOW,
          component: <ReloadPageModal />,
        }),
      ],
    },
    [ACTIONS.DOWNLOAD_APP]: { actions: ['downloadApp'] },
  },
  states: {
    [STATES.INIT]: {
      always: [
        {
          guard: 'isMobileDevice',
          target: STATES.MOBILE_ACCESS,
        },
        {
          target: STATES.API_INIT,
        },
      ],
    },
    [STATES.MOBILE_ACCESS]: {
      entry: raise({
        type: MODAL_ACTIONS.SHOW,
        component: <MobileAccessModal />,
      }),
    },
    [STATES.API_INIT]: {
      tags: [LOADING],
      invoke: {
        src: 'apiInit',
        onDone: [
          {
            guard: 'hasEditorIdSearchParam',
            actions: [
              'saveInitData',
              {
                type: 'internalMetric',
                params: { name: 'viewed' },
              },
            ],
            target: STATES.INIT_SHARING_SESSION,
          },
          {
            actions: [
              'saveInitData',
              {
                type: 'internalMetric',
                params: { name: 'viewed' },
              },
            ],
            target: STATES.REQUEST_FIRST_PAGE,
          },
        ],
        onError: {
          actions: [
            {
              type: 'raiseError',
              params: ({ event }) => ({
                title: 'Failed to initialize the Page Editor API.',
                error: event.error,
              }),
            },
          ],
        },
      },
    },
    [STATES.INIT_SHARING_SESSION]: {
      tags: [LOADING],
      invoke: {
        src: 'sharingSessionInit',
        input: ({ context }) => {
          return { id: getSearchParam('editorid'), api: context.api };
        },
        onDone: {
          target: `${STATES.INITIALIZED}.${STATES.GENERAL}.${STATES.SELECTING_VERSION}`,
          actions: ['saveEditorState', 'setActivePage', 'savePageContext'],
        },
        onError: {
          actions: [
            {
              type: 'raiseError',
              params: ({ event }) => ({
                title: 'Failed to load the session.',
                error: event.error,
                context: { editorId: getSearchParam('editorid') },
              }),
            },
          ],
        },
      },
    },
    [STATES.REQUEST_FIRST_PAGE]: {
      entry: raise({
        type: MODAL_ACTIONS.SHOW,
        component: <AddFirstPageModal />,
      }),
      on: {
        [ACTIONS.ADD_PAGE]: {
          target: STATES.ADDING_FIRST_PAGE,
        },
      },
    },
    [STATES.ADDING_FIRST_PAGE]: {
      tags: [LOADING],
      entry: [
        'closeModal',
        'setUrlToLoad',
        {
          type: 'internalMetric',
          params: { name: 'first_url_entered' },
        },
        'recordPageLoadStartTime',
      ],
      invoke: {
        src: 'sessionInit',
        input: ({ event, context }) => ({ url: event.url, api: context.api }),
        onDone: {
          target: `${STATES.INITIALIZED}.${STATES.GENERAL}.${STATES.SELECTING_VERSION}`,
          actions: [
            'saveEditorState',
            'setActivePage',
            'savePageContext',
            'idToSearchParam',
            'clearUrlToLoad',
            'pageLoadedMetric',
          ],
        },
        onError: {
          actions: [
            {
              type: 'honeyBadgerNotify',
              params: ({ event, context }) => ({
                error: event.error,
                context: { url: context.urlToLoad },
              }),
            },
          ],
          target: STATES.FIRST_PAGE_LOAD_FAILED,
        },
      },
      after: {
        pageLoadTimeout: {
          actions: [
            raise({
              type: MODAL_ACTIONS.SHOW,
              component: <SlowLoadingPageModal />,
            }),
          ],
        },
      },
      on: {
        [ACTIONS.ERROR_MODAL_BACK]: {
          target: STATES.REQUEST_FIRST_PAGE,
          actions: ['apiCancel'],
        },
      },
    },
    [STATES.FIRST_PAGE_LOAD_FAILED]: {
      entry: raise({
        type: MODAL_ACTIONS.SHOW,
        component: <ErrorLoadingPageModal overlayClassName="!bg-gray-500" />,
      }),
      on: {
        [ACTIONS.ERROR_MODAL_BACK]: {
          target: STATES.REQUEST_FIRST_PAGE,
        },
      },
    },
    [STATES.INITIALIZED]: {
      type: 'parallel',
      states: {
        [STATES.GENERAL]: {
          initial: STATES.IDLE,
          invoke: [
            {
              src: 'apiEventListeners',
              input: ({ context }) => ({ api: context.api }),
            },
            {
              src: 'beforeUnloadListener',
              id: BEFORE_UNLOAD_LISTENER_ID,
            },
            {
              src: 'aiInit',
              onDone: {
                actions: ['saveAI'],
              },
              onError: {
                actions: [
                  {
                    type: 'raiseError',
                    params: ({ event }) => ({
                      title: 'Failed to initialize AI connection.',
                      error: event.error,
                    }),
                  },
                ],
              },
            },
          ],
          on: {
            [ACTIONS.CHANGE_DEVICE]: { actions: ['changeDevice'] },
            [ACTIONS.CHANGE_VOICE]: { actions: ['changeVoice'] },
            [ACTIONS.POPUPS_DETECTED]: { actions: ['apiHidePopups'] },
            [ACTIONS.STATUS_CHANGE]: { actions: ['saveStatus', 'forwardDirtyChanged'] },
            [ACTIONS.ELEMENT_LOCKED]: {
              actions: [
                {
                  type: 'internalMetric',
                  params: { name: 'element_locked' },
                },
              ],
            },
            [ACTIONS.TOGGLE_PAGES]: { actions: ['togglePages'] },
            [ACTIONS.TOGGLE_VERSIONS]: { actions: ['toggleVersions'] },
            [ACTIONS.TOGGLE_CONTROLS]: { actions: ['toggleControls'] },
          },
          states: {
            [STATES.IDLE]: {
              entry: ['raiseSavedEvent'],
              on: {
                [ACTIONS.SHOW_ADD_PAGE]: { target: STATES.ADD_PAGE_MODAL },
                [ACTIONS.ADD_PAGE]: { target: STATES.ADDING_PAGE },
                [ACTIONS.SAVE]: { target: STATES.SAVING },
                [ACTIONS.SAVE_AS_NEW]: { target: STATES.SAVING },
                [ACTIONS.UPDATE_TEXT]: { actions: ['apiSetText'] }, // temp
                [ACTIONS.SELECT_PAGE]: [
                  {
                    guard: and([not('isSamePage'), not('isDirty')]),
                    actions: {
                      type: 'setActivePage',
                      params: ({ event }) => ({ pageId: event.pageId }),
                    },
                    target: STATES.SELECTING_VERSION,
                  },
                  {
                    guard: not('isSamePage'),
                    actions: ['saveEvent'],
                    target: STATES.PROMPT_UNSAVED_CHANGES,
                  },
                ],
                [ACTIONS.SELECT_VERSION]: [
                  {
                    guard: and([not('isSameVersion'), not('isDirty')]),
                    target: STATES.SELECTING_VERSION,
                  },
                  {
                    guard: not('isSameVersion'),
                    actions: ['saveEvent'],
                    target: STATES.PROMPT_UNSAVED_CHANGES,
                  },
                ],
                [ACTIONS.UNDO]: { actions: ['apiUndo'] },
                [ACTIONS.REDO]: { actions: ['apiRedo'] },
                [ACTIONS.SHOW_HISTORY]: { actions: ['showHistory'] },
                [ACTIONS.PUBLISH]: { target: STATES.PUBLISHING },
                [ACTIONS.SELECT_ELEMENT]: { actions: ['apiSelectElement'] },
                [ACTIONS.GENERATE_FULL_PAGE]: { target: STATES.GENERATING_FULL_PAGE },
              },
            },
            [STATES.PROMPT_UNSAVED_CHANGES]: {
              entry: raise({
                type: MODAL_ACTIONS.SHOW,
                component: <SaveChangesModal />,
              }),
              on: {
                [ACTIONS.SAVE]: { target: STATES.SAVING },
                [ACTIONS.SAVE_AS_NEW]: { target: STATES.SAVING },
                [ACTIONS.DISCARD]: { actions: ['setClean'], target: STATES.IDLE },
                [ACTIONS.CANCEL]: { actions: ['clearSavedEvent'], target: STATES.IDLE },
              },
            },
            [STATES.ADD_PAGE_MODAL]: {
              entry: [
                raise({
                  type: MODAL_ACTIONS.SHOW,
                  component: <AddPageModal />,
                }),
              ],
              on: {
                [ACTIONS.ADD_PAGE]: [
                  {
                    guard: not('isDirty'),
                    target: STATES.ADDING_PAGE,
                  },
                  {
                    actions: ['saveEvent'],
                    target: STATES.PROMPT_UNSAVED_CHANGES,
                  },
                ],
                [ACTIONS.CANCEL]: { target: STATES.IDLE },
              },
            },
            [STATES.ADDING_PAGE]: {
              tags: [LOADING],
              entry: ['closeModal', 'setUrlToLoad', 'elementUnSelected', 'clearSaved', 'recordPageLoadStartTime'],
              invoke: {
                id: 'addPage',
                src: 'apiAddPage',
                input: ({ context, event }) => ({ url: event.url, api: context.api }),
                onDone: {
                  target: STATES.IDLE,
                  actions: [
                    'saveEditorState',
                    'setActivePage',
                    'savePageContext',
                    {
                      type: 'internalMetric',
                      params: { name: 'page_added' },
                    },
                    'clearUrlToLoad',
                    'pageLoadedMetric',
                  ],
                },
                onError: {
                  target: STATES.PAGE_LOAD_FAILED,
                  actions: [
                    {
                      type: 'honeyBadgerNotify',
                      params: ({ event, context }) => ({
                        error: event.error,
                        context: { url: context.urlToLoad },
                      }),
                    },
                  ],
                },
              },
              after: {
                pageLoadTimeout: {
                  actions: [
                    raise({
                      type: MODAL_ACTIONS.SHOW,
                      component: <SlowLoadingPageModal />,
                    }),
                  ],
                },
              },
              on: {
                [ACTIONS.ERROR_MODAL_BACK]: {
                  target: STATES.ADD_PAGE_MODAL,
                  actions: ['apiCancel'],
                },
              },
            },
            [STATES.PAGE_LOAD_FAILED]: {
              entry: raise({
                type: MODAL_ACTIONS.SHOW,
                component: <ErrorLoadingPageModal />,
              }),
              on: {
                [ACTIONS.ERROR_MODAL_BACK]: {
                  target: STATES.ADD_PAGE_MODAL,
                },
              },
            },
            [STATES.SAVING]: {
              tags: [LOADING],
              entry: ['setSaving', 'elementUnSelected', { type: 'setVersionsOpen', params: { open: true } }],
              invoke: {
                src: 'apiSave',
                input: ({ context, event }) => ({
                  api: context.api,
                  asNew: event.type === ACTIONS.SAVE_AS_NEW,
                  versions: context.editor.pages.find((p) => p.id === context.activePageId)?.versions ?? [],
                  usedVoice: !context.doneManualEdits && context.generatorVoiceUsed?.replace(/^lucky: /, ''),
                  activeVersion: context.editor.pages
                    .find((p) => p.id === context.activePageId)
                    ?.versions.find((v) => v.id === context.activeVersionId),
                }),
                onDone: {
                  target: STATES.IDLE,
                  actions: [
                    {
                      type: 'internalMetric',
                      params: ({ event, context }) => ({
                        name: 'version_saved',
                        params: {
                          saved_as: event.output.asNew,
                          used_generator: !!context.generatorVoiceUsed,
                          voice: context.generatorVoiceUsed,
                          used_editor: context.doneManualEdits,
                        },
                      }),
                    },
                    'saveEditorState',
                    'setSaved',
                    {
                      type: 'setActiveVersion',
                      params: ({ event }) => ({ versionId: event.output.versionId }),
                    },
                    'clearDoneManualEdits',
                    'clearGeneratorVoiceUsed',
                    'setClean',
                  ],
                },
                onError: {
                  target: STATES.IDLE,
                  actions: [
                    {
                      type: 'raiseError',
                      params: ({ event }) => ({
                        title: 'Failed to save.',
                        error: event.error,
                      }),
                    },
                  ],
                },
              },
              exit: ['clearSaving'],
            },
            [STATES.SELECTING_VERSION]: {
              tags: [LOADING],
              entry: [
                {
                  type: 'setActiveVersion',
                  params: ({ event, context }) => ({
                    versionId:
                      event.type === ACTIONS.SELECT_VERSION ? event.versionId : selectDefaultVersionId({ context }),
                  }),
                },
                'elementUnSelected',
                'clearSaved',
              ],
              invoke: {
                src: 'apiSelectVersion',
                input: ({ context }) => ({
                  api: context.api,
                  pageId: context.activePageId,
                  versionId: context.activeVersionId,
                }),
                onDone: {
                  target: STATES.IDLE,
                  actions: ['savePageContext', 'clearDoneManualEdits', 'clearGeneratorVoiceUsed'],
                },
                onError: {
                  target: STATES.IDLE,
                  actions: [
                    {
                      type: 'raiseError',
                      params: ({ event }) => ({
                        title: 'Failed to load version.',
                        error: event.error,
                      }),
                    },
                  ],
                },
              },
            },
            [STATES.PUBLISHING]: {
              tags: [LOADING],
              entry: [{ type: 'setVersionsOpen', params: { open: true } }],
              invoke: {
                src: 'apiPublish',
                input: ({ context }) => ({
                  api: context.api,
                  pageId: context.activePageId,
                  versionId: context.activeVersionId,
                }),
                onDone: {
                  target: STATES.IDLE,
                  actions: [
                    {
                      type: 'internalMetric',
                      params: { name: 'published' },
                    },
                  ],
                },
                onError: {
                  target: STATES.IDLE,
                  actions: [
                    {
                      type: 'raiseError',
                      params: ({ event }) => ({
                        title: 'Failed to publish version.',
                        error: event.error,
                      }),
                    },
                  ],
                },
              },
            },
            [STATES.GENERATING_FULL_PAGE]: {
              tags: [LOADING],
              entry: ['clearSaved'],
              invoke: {
                src: 'generateFullPage',
                input: ({ context, event }) => ({
                  api: context.api,
                  ai: context.ai,
                  voice: event.voice,
                  pageContext: context.pageContext,
                }),
                onDone: {
                  target: STATES.IDLE,
                  actions: [
                    {
                      type: 'internalMetric',
                      params: ({ event }) => ({
                        name: 'generator_used',
                        params: {
                          voice: event.output.voice,
                          had_locked_elements: event.output.hadLockedElements,
                          elapsed_time: event.output.elapsedTime,
                        },
                      }),
                    },
                    'setGeneratorVoiceUsed',
                  ],
                },
                onError: {
                  target: STATES.IDLE,
                  actions: [
                    {
                      type: 'raiseError',
                      params: ({ event }) => ({
                        title: 'Failed to generate content.',
                        error: event.error,
                      }),
                    },
                  ],
                },
              },
            },
          },
        },
        [STATES.EDITOR]: {
          initial: STATES.HIDDEN,
          on: {
            [ACTIONS.SELECTED_ELEMENT_UPDATED]: { actions: ['selectedElementUpdated'] },
          },
          states: {
            [STATES.HIDDEN]: {
              on: {
                [ACTIONS.ELEMENT_SELECTED]: {
                  actions: [
                    'elementSelected',
                    {
                      type: 'setVersionsOpen',
                      params: { open: false },
                    },
                    {
                      type: 'setControlsOpen',
                      params: { open: true },
                    },
                  ],
                  target: STATES.EDIT,
                },
              },
            },
            [STATES.EDIT]: {
              on: {
                [ACTIONS.ELEMENT_SELECTED]: {
                  actions: [
                    'elementSelected',
                    {
                      type: 'setVersionsOpen',
                      params: { open: false },
                    },
                    {
                      type: 'setControlsOpen',
                      params: { open: true },
                    },
                  ],
                },
                [ACTIONS.ELEMENT_UNSELECTED]: { actions: ['elementUnSelected'], target: STATES.HIDDEN },
                [ACTIONS.AI_REPHRASE]: { target: STATES.AI_SUGGESTIONS },
                [ACTIONS.SET_SELECTED_EL_TEXT]: {
                  actions: ['setSelectedElText', 'editedContentArea', 'clearSaved'],
                },
                [ACTIONS.SET_SELECTED_EL_HTML]: {
                  actions: ['setSelectedElHTML', 'editedContentArea', 'clearSaved'],
                },
                [ACTIONS.SET_SELECTED_EL_ATTRIBUTE]: {
                  actions: ['setSelectedElAttribute', 'editedContentArea', 'clearSaved'],
                },
                [ACTIONS.SET_SELECTED_EL_IMAGE]: {
                  actions: ['setSelectedElImage', 'editedManualImage', 'clearSaved'],
                },
              },
            },
            [STATES.AI_SUGGESTIONS]: {
              on: {
                [ACTIONS.BACK]: { target: STATES.EDIT },
                [ACTIONS.ELEMENT_SELECTED]: {
                  actions: [
                    'elementSelected',
                    {
                      type: 'setVersionsOpen',
                      params: { open: false },
                    },
                    {
                      type: 'setControlsOpen',
                      params: { open: true },
                    },
                  ],
                  target: STATES.EDIT,
                },
                [ACTIONS.ELEMENT_UNSELECTED]: { actions: ['elementUnSelected'], target: STATES.HIDDEN },
                [ACTIONS.SET_SELECTED_EL_TEXT]: {
                  actions: ['setSelectedElText', 'editedAiRephrase', 'clearSaved'],
                },
                [ACTIONS.SET_SELECTED_EL_HTML]: {
                  actions: ['setSelectedElHTML', 'editedAiRephrase', 'clearSaved'],
                },
                [ACTIONS.SET_SELECTED_EL_ATTRIBUTE]: {
                  actions: ['setSelectedElAttribute', 'editedAiRephrase', 'clearSaved'],
                },
                [ACTIONS.SET_SELECTED_EL_IMAGE]: {
                  actions: ['setSelectedElImage', 'editedAiImage', 'clearSaved'],
                },
                [ACTIONS.IMAGE_GENERATION_ERROR]: {
                  actions: [
                    {
                      type: 'raiseError',
                      params: ({ event }) => ({
                        title: 'Failed to generate image suggestions.',
                        error: '',
                        context: { image: event.img },
                      }),
                    },
                  ],
                  target: STATES.EDIT,
                },
              },
            },
          },
        },
      },
    },
  },
});

export const MainMachineContext = createActorContext(mainMachine, {
  //inspect,
});

export function initApi() {
  if (singleApi) {
    return singleApi;
  }
  singleApi = new PageEditorAPI({
    width: '100%',
    height: '100%',
    root: document.querySelector('#page-wrapper'),
    //mode: 'production',
  });

  return singleApi;
}

export async function initCrazyAI() {
  if (singleAI) return singleAI;
  const ai = process.env.NODE_ENV === 'test' ? mockAI : crazyAi; // mock AI service for e2e tests
  singleAI = await ai.connection({
    version: 'v2',
    env: 'prod',
    onError: (error) => {
      console.error(error);
    },
  });

  return singleAI;
}

function selectDefaultVersionId({ context }) {
  let versionId;
  const versions = context.editor.pages.find((p) => p.id === context.activePageId).versions;
  if (versions.length > 0) {
    versionId = versions.reduce((acc, v) => (v.updated_at > acc.updated_at ? v : acc), versions[0]).id;
  }

  return versionId;
}
